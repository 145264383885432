// import React, { useState } from 'react';
// import ReCAPTCHA from 'react-google-recaptcha';
// import './ContactForm.css';
// import { db } from '../Firebase';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// function ContactForm() {
//     const [isVerified, setIsVerified] = useState(false);
//     const [formData, setFormData] = useState({
//         name: '',
//         email: '',
//         phone: '',
//         subject: '',
//         message: ''
//     });

//     // const [id, setId] = useState(1); 

//     const handleChange = (e) => {
//         setFormData({ ...formData, [e.target.name]: e.target.value });
//     };
//     const handleRecaptchaChange = (token) => {
//         if (token) {
//             setIsVerified(true);
//         }
//     };

//     const handleSubmit = async (event) => {
//         event.preventDefault();
//         // const customId = id.toString();
//         // Handle form submission, only proceed if reCAPTCHA is verified
//         if (isVerified) {
//             try {
//                 const docRef = db.collection('Contact').doc();
//                 await docRef.set({
//                     date: new Date(),
//                     email: formData.email,
//                     message: formData.message,
//                     name: formData.name,
//                     phone: formData.phone,
//                     subject: formData.subject
//                 });
//                 console.log('Form submitted and data saved to Firestore!');
//                 toast.success('Form submitted successfully!');
//                 setIsVerified(false);
//                 // Optionally, reset form data here
//                 setFormData({
//                     name: '',
//                     email: '',
//                     phone: '',
//                     subject: '',
//                     message: ''
//                 });
//                 // setId(id + 1);
//             } catch (error) {
//                 console.error('Error saving data to Firestore: ', error);
//                 toast.error('Failed to submit form. Please try again later.');
//             }
//         } else {
//             alert("Please verify that you're not a robot.");
//         }
//     };


//     return (
//         <div>
//             <div className='Contact-main-con'>
//                 <h3>Write us a message</h3>
//                 <form onSubmit={handleSubmit}>
//                     <input
//                         type='text'
//                         placeholder='Enter your name'
//                         className='input'
//                         name='name'
//                         value={formData.name}
//                         onChange={handleChange}
//                         required
//                     />
//                     <div className='inner-form'>
//                         <input
//                             type='email'
//                             placeholder='Enter your email'
//                             className='input-email'
//                             name='email'
//                             value={formData.email}
//                             onChange={handleChange}
//                             required
//                         />
//                         <input
//                             type='text'
//                             placeholder='Enter your phone'
//                             className='input-phone'
//                             name='phone'
//                             value={formData.phone}
//                             onChange={handleChange}
//                             required
//                         />
//                     </div>
//                     <input
//                         type='text'
//                         placeholder='Subject'
//                         className='input'
//                         name='subject'
//                         value={formData.subject}
//                         onChange={handleChange}
//                         required
//                     />
//                     <textarea
//                         type='text'
//                         placeholder='Message'
//                         className='input'
//                         name='message'
//                         value={formData.message}
//                         onChange={handleChange}
//                         required
//                     />
//                     <ReCAPTCHA
//                         // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
//                         // sitekey='6LcVTugpAAAAALGZ1wwtz6st2jZjJIBplcjascGF'
//                       sitekey='6LeQ_u4pAAAAABdpEg_tpVHCW0NXwjtIo0Dac1Mg'
//                         onChange={handleRecaptchaChange}
//                         onExpired={() => setIsVerified(false)}
                        
//                     />
//                     <button type='submit' className='bttn-contact'>Submit</button>
//                 </form>
//             </div>
//             <ToastContainer />
//         </div>
//     );
// }

// export default ContactForm;

import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './ContactForm.css';
import { db } from '../Firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ContactForm() {
    const [isVerified, setIsVerified] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    const recaptchaRef = useRef(null); // Create a ref for the reCAPTCHA component

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleRecaptchaChange = (token) => {
        if (token) {
            setIsVerified(true);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isVerified) {
            try {
                const docRef = db.collection('Contact').doc();
                await docRef.set({
                    date: new Date(),
                    email: formData.email,
                    message: formData.message,
                    name: formData.name,
                    phone: formData.phone,
                    subject: formData.subject
                });
                console.log('Form submitted and data saved to Firestore!');
                toast.success('Form submitted successfully!');
                setIsVerified(false);
                recaptchaRef.current.reset(); // Reset the reCAPTCHA checkbox

                // Optionally, reset form data here
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    subject: '',
                    message: ''
                });
            } catch (error) {
                console.error('Error saving data to Firestore: ', error);
                toast.error('Failed to submit form. Please try again later.');
            }
        } else {
            alert("Please verify that you're not a robot.");
        }
    };

    return (
        <div>
            <div className='Contact-main-con'>
                <h3>Write us a message</h3>
                <form onSubmit={handleSubmit}>
                    <input
                        type='text'
                        placeholder='Enter your name'
                        className='input'
                        name='name'
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    <div className='inner-form'>
                        <input
                            type='email'
                            placeholder='Enter your email'
                            className='input-email'
                            name='email'
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type='text'
                            placeholder='Enter your phone'
                            className='input-phone'
                            name='phone'
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <input
                        type='text'
                        placeholder='Subject'
                        className='input'
                        name='subject'
                        value={formData.subject}
                        onChange={handleChange}
                        required
                    />
                    <textarea
                        type='text'
                        placeholder='Message'
                        className='input'
                        name='message'
                        value={formData.message}
                        onChange={handleChange}
                        required
                    />
                    <ReCAPTCHA
                        sitekey='6LeQ_u4pAAAAABdpEg_tpVHCW0NXwjtIo0Dac1Mg'
                        onChange={handleRecaptchaChange}
                        onExpired={() => setIsVerified(false)}
                        ref={recaptchaRef} // Attach the ref to the reCAPTCHA component
                    />
                    <button type='submit' className='bttn-contact'>Submit</button>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
}

export default ContactForm;


// firebase.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'; // Import Firestore if you need it
import 'firebase/compat/auth'; // Import Authentication if you need it

const firebaseConfig = {
  apiKey: "AIzaSyDOBrYp8OqINMcK1I__76Diy3uTaVpHkd0",
  authDomain: "dataadefense-ea44c.firebaseapp.com",
  projectId: "dataadefense-ea44c",
  storageBucket: "dataadefense-ea44c.appspot.com",
  messagingSenderId: "611955044495",
  appId: "1:611955044495:web:0e754f571662c451c200fa",
  measurementId: "G-JE0LXRZ5MV"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();

export { db, auth }; // Export Firestore and Authentication if you need them
export default firebase; // Export Firebase object if you need it for other functionalities

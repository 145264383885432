import React from 'react'
import Navbar1 from '../Components/Navbar1'
import Home1 from '../Components/Home1'
import Home2 from '../Components/Home2'
import Footer1 from '../Components/Footer1'
import Home3 from '../Components/Home3'
import ServiceHome from '../Components/ServiceHome'
import LatestBlog from '../Components/LatestBlog'
function Home() {
  return (
    <div>
      <Navbar1 />
      <Home1 />

      <Home2 />
      <ServiceHome/>
      <LatestBlog/>
      {/* <Home3 /> */}

      <Footer1 />
    </div>
  )
}

export default Home
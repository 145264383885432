import React from 'react'
import './LetsTalk.css'
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
function LetsTalk() {
    const navigate = useNavigate();
    const location = useLocation();
  
    // Do not show the button on the contact page
    if (location.pathname === '/contact') {
      return null;
    }
  
  return (
    <button
    className="lets-talk-button"
    onClick={() => navigate('/contact')}
  >
        <FontAwesomeIcon icon={faPhone} className='icon-phone'/>
    Let's Talk

  </button>
  )
}

export default LetsTalk
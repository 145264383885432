import React from 'react'
import Navbar1 from '../Components/Navbar1'
import Footer1 from '../Components/Footer1'
import AboutCon from '../Components/AboutCon'
import ContactForm from '../Components/ContactForm'
function About() {
  return (
    <div>
        <Navbar1/>
        <AboutCon/>
        <ContactForm/>
        <Footer1/>
    </div>
  )
}

export default About
import React, { useState, useEffect } from 'react';
import './HomeServices.css';
import { useNavigate } from 'react-router-dom';
import {Fade} from 'react-reveal';

function HomeServices() {
  const [isVisible, setIsVisible] = useState(false);
  const navigate=useNavigate();
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight;
    const elementPosition = document.querySelector('.main-home-service').offsetTop;
    if (scrollPosition > elementPosition) {
      setIsVisible(true);
    }
  };

  return (
    <div className='main-home-service'>


<div className='lg-screen'>

  <div className='row1'>
    {/* <div className='col-service-new'>
    <div className='img-div1'></div>
    </div>
    <div className='col-service-new'>
    <div className='img-div2'></div>
    </div>
    <div className='cole-service-new'>
    <div className='img-div3'></div>
    </div>
    <div className='col-service-new'>
    <div className='img-div4'></div>
    </div> */}
    <div className='img-div1'></div>
    <div className='img-div2'></div>
    <div className='img-div3'></div>
    <div className='img-div4'></div>
  </div>
  <Fade bottom>
  <div className='row1'>
    <div className='col-service-new'>
      <h3>Security Assessment & Penetration Testing</h3>
    </div>
    <div className='col-service-new'>
      <h3>Managed Defence Operations (MDO)</h3>
    </div>
    <div className='col-service-new'>
      <h3>Research Services</h3>
    </div>
    <div className='col-service-new'>
      <h3>Scam Aid</h3>
    </div>
  </div>
  <div className='row1'>
    <div className='col-service-new'>
    <p>Conducting a comprehensive security audit to fortify organizational defenses.</p>
    </div>
    <div className='col-service-new'>
    <p>We offer MDO and MDR, bolstering defenses with expert care,
Shielding organizations from cyber threats with vigilance rare.</p>
    </div>
    <div className='col-service-new'>
    <p>We provide custom Cybersecurity and AI research services for Cybersecurity products.</p>
    </div>
    <div className='col-service-new'>
    <p>Scam aid services promise relief, but ensnare with false hope,
Exploiting vulnerability, they profit as victims struggle to cope.</p>
    </div>
  </div>

  <div className='row1'>
    <div className='col-service-new'>
    <button onClick={()=>navigate('/sapt')}>Read more</button>
    </div>
    <div className='col-service-new'>
    <button onClick={()=>navigate('/mdo')}>Read more</button>
    </div>
    <div className='col-service-new'>
    <button onClick={()=>navigate('/research')}>Read more</button>
    </div>
    <div className='col-service-new'>
    <button onClick={()=>navigate('/scam')}>Read more</button>
    </div>
  </div>
  </Fade>
</div>



      <div className={`row-service ${isVisible ? 'visible' : ''}`}>
        <Fade bottom>
        <div className='col-service'>
          <div className='img-div1'></div>
          <h3>Security Assessment & Penetration Testing</h3>
          <p>Conducting a comprehensive security audit to fortify organizational defenses.</p>
             <button className='ser1-bttn' onClick={()=>navigate('/sapt')}>Read more</button>
        </div>
        <div className='col-service'>
          <div className='img-div2'></div>
          <h3>Managed Defence Operations (MDO)</h3>
          <p className='ser2'>We offer MDO and MDR, bolstering defenses with expert care,
Shielding organizations from cyber threats with vigilance rare.</p>
          <button onClick={()=>navigate('/mdo')}>Read more</button>
        </div>
      {/* </div> */}

      {/* <div className={`row-service ${isVisible ? 'visible' : ''}`}> */}
        <div className='col-service'>
          <div className='img-div3'></div>
          <h3>Research Services</h3>
          <p>We provide custom Cybersecurity and AI research services for Cybersecurity products.</p>
          <button className='ser1-bttn' onClick={()=>navigate('/research')}>Read more</button>
        </div>
        <div className='col-service'>
          <div className='img-div4'></div>
          <h3>Scam Aid</h3>
          <p>Scam aid services promise relief, but ensnare with false hope,
Exploiting vulnerability, they profit as victims struggle to cope.</p>
          <button onClick={()=>navigate('/scam')}>Read more</button>
        </div>
        </Fade>
      </div>
     
    </div>
  );
}

export default HomeServices;

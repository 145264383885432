import React from 'react'
import Navbar1 from '../Components/Navbar1'
import Footer1 from '../Components/Footer1'
import ContactForm from '../Components/ContactForm'
import './Contact.css'


function Contact() {
  return (
    <div>
        <Navbar1/>
        <div className='contact-con-main'>
            <div className='title-con'>
                {/* <h3 style={{color:'#191919'}}>Contact Us</h3> */}
            </div>
        </div>
<ContactForm/>
        <Footer1/>
    </div>
  )
}

export default Contact
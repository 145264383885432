import React from 'react'
import './Navbar1.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faArrowDown} from '@fortawesome/free-solid-svg-icons';
// import logo2 from '../newlogo.png';
// import logo from '../footerlogo1.png'
import logo2 from '../logoupdated.png'
import NavDropdown from 'react-bootstrap/NavDropdown';
function Navbar1() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  return (
    <div>
        <div className='mob-nav'>
        <Navbar className='nav-header'>
        <Container className='container'>
            {/* <div className='logo-main'> */}
          <Navbar.Brand href="/" className='logo-main'>
            <div className='mob-logo-div'>
          {/* <img src={logo} className='logo-mob-1'/>  */}
            <img src={logo2} alt='Data Defense' className='real-logo'/>
            </div>
          </Navbar.Brand>
          {/* </div> */}

          <div className='nav-right'>
          <Button variant='outline-dark' onClick={handleShow} className='mob-nav-btn'>
        <FontAwesomeIcon icon={faBars} className='icon-mob'/>
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton className='title-mob'>
          <Offcanvas.Title className='title-offcanvas'>
          <div className='mob-logo-div'>
          {/* <img src={logo} className='logo-mob-1'/>  */}
            <img src={logo2} alt='Data Defense' className='real-logo'/>
            </div>
            {/* <img src={logo2} alt='Data Defense' className='real-logo2' /> */}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <div className='mob-nav-body'>
            {/* <Nav.Link href="/" id='navLink'>Home</Nav.Link> */}
            {/* <Nav.Link href="/services" id='navLink'>Services</Nav.Link> */}
            <NavDropdown title="Services" id="navLink" >
              <NavDropdown.Item href="/mdo" >Managed Defense Operations (MDO)</NavDropdown.Item>
              <NavDropdown.Item href="/sapt">
                Security Assessment & Penetration Testing
              </NavDropdown.Item>
              <NavDropdown.Item href="/research">Research Services</NavDropdown.Item>
              <NavDropdown.Item href="/scam">Scam Aid</NavDropdown.Item>
              {/* <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item> */}
            </NavDropdown>
            {/* <Nav.Link href="/scam" id='navLink'>Scam Aid</Nav.Link> */}
            
            <Nav.Link href="/blog" id='navLink'>Blog</Nav.Link>
            
            <Nav.Link href="/about" id='navLink'>About us</Nav.Link>
            
            <Nav.Link href="/contact" id='navLink'>Contact us</Nav.Link>

            </div>
           
        </Offcanvas.Body>
      </Offcanvas>


          </div>
          </Container>

          </Navbar>



      
        </div>
        <div className='largescreen' >
<Navbar>
        <Container>
          <Navbar.Brand href="/">
            {/* <div className='nav-div-logo'> */}
            {/* <img src={logo} className='logo1'/>  */}
            <img src={logo2} className='logo'/>
            {/* </div> */}
          </Navbar.Brand>
          <Nav className='nav'>
            {/* <Nav.Link href="/" id='navLink'>Home</Nav.Link> */}
            {/* <Nav.Link href="/services" id='navLink'>Services</Nav.Link> */}
            
            <NavDropdown title="Services" id="navLink">
            
              <NavDropdown.Item href="/mdo" >Managed Defense Operations (MDO)</NavDropdown.Item>
              <NavDropdown.Item href="/sapt">
              Security Assessment & Penetration Testing
              </NavDropdown.Item>
              <NavDropdown.Item href="/research">Research Services</NavDropdown.Item>
              <NavDropdown.Item href='/scam'>Scam Aid</NavDropdown.Item>
              {/* <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item> */}
            </NavDropdown>
            {/* <Nav.Link href="/scam" id='navLink'>Scam Aid</Nav.Link> */}
            <Nav.Link href="/blog" id='navLink'>Blog</Nav.Link>
            <Nav.Link href="/about" id='navLink'>About us</Nav.Link>
            <Nav.Link href="/contact" id='navLink'>Contact us</Nav.Link>
            {/* </div> */}
          </Nav>
        </Container>
      </Navbar>
      </div>
    </div>
  )
}

export default Navbar1
import React from 'react'
import Navbar1 from '../Components/Navbar1'
import Footer1 from '../Components/Footer1'
import './Blog.css'
import BlogDisplay from '../Components/BlogDisplay'

function Blog() {
  return (
    <div>
        <Navbar1/>
        <div className='blog-con-main'>
            <div className='title-con'>
                <h3>Blog</h3>
            </div>
        </div>
        <div className='quote'>
            <h5>Blogging is good for your career. A well-executed blog sets you apart as an expert in your field.</h5>
        </div>
        <BlogDisplay/>
        <Footer1/>
    </div>
  )
}

export default Blog
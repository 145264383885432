import './App.css';
import Navbar1 from './Components/Navbar1';
import Home from './Pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Blog from './Pages/Blog';
import BelogDetails from './Components/BelogDetails';
import MDO from './Pages/MDO';
import Pentration from './Pages/Pentration';
import Research from './Pages/Research';
import Scam from './Pages/Scam';
import ScrollToTopButton from './Components/ScrollToTop';
import useScrollToTop from './Components/ScrollTopPage';
import LetsTalkButton from './LetsTalk'
function App() {
  useScrollToTop();
  return (
    <div className="App">
     {/* <Home/> */}
     <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/contact' element={<Contact/>}/>
      <Route path='/blog' element={<Blog/>}/>
      <Route path="/blog-details/:id" element={<BelogDetails/>} />
      <Route path='/mdo' element={<MDO/>}/>
      <Route path='/sapt' element={<Pentration/>}/>
      <Route path="/research" element={<Research/>}/>
      <Route path='/scam' element={<Scam/>}/>
     </Routes>  
     <ScrollToTopButton/>
     <LetsTalkButton/>
    </div>
  );
}

export default App;

import React, {useState, useEffect} from 'react'
import './ServiceHome.css'
import { useNavigate } from 'react-router-dom'
import {Fade} from 'react-reveal'
function ServiceHome() {
    const navigate=useNavigate();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const elementPosition = document.querySelector('.main-home-service').offsetTop;
      if (scrollPosition > elementPosition) {
        setIsVisible(true);
      }
    };
  return (
    <div className={`main-service-comp2 ${isVisible ? 'visible' : ''}`}>
        <div className='img-service-div'></div>
        <div className='text-service-main'>
          <Fade right>
            <h3>Scam Aid</h3>
            <h5>Defend Against Cyber Scams: Our Comprehensive Security Solutions</h5>
            <p>Shielding against SMS, email, and other scams, our cybersecurity services fortify defenses, safeguarding clients from digital threats.</p>
            <button onClick={()=>navigate('/scam')}>Read more</button>
            </Fade>
        </div>
    </div>
  )
}

export default ServiceHome
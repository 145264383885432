import React, { useState, useEffect } from 'react';
import './LatestBlog.css'
import { db } from '../Firebase'
import { useNavigate, Link } from 'react-router-dom';
import {Fade} from 'react-reveal'
function LatestBlog() {
    const navigate = useNavigate();
    const [blogs, setBlogs] = useState([]);
    const currentDate = new Date();
    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const snapshot = await db.collection('Blog').get();
                const fetchedBlogs = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setBlogs(fetchedBlogs);
            } catch (error) {
                console.error('Error fetching blogs: ', error);
            }
        };


        fetchBlogs();
    }, []);

    const formatDate = (timestamp) => {
        const date = new Date(timestamp.seconds * 1000); // Convert Firestore timestamp to JavaScript Date object
        return date.toLocaleDateString(); // Format date as a string
    };

    const handleReadMore = (blogId) => {
        navigate(`/blog-details/${blogId}`);
    };

    // Filter recent blogs based on publish date
    const recentBlogs = blogs.filter(blog => {
        const publishDate = blog.publish.toDate();
        const twentyFourHoursAgo = new Date(currentDate - 24 * 60 * 60 * 1000);
        return publishDate >= twentyFourHoursAgo && publishDate <= currentDate;
    });
  return (
    <div>
        <div className='Home2content'>
            <div className='content-wraper'>
                <div className='main'>
                    <section className='section-home2'>
                        <div className='section-inner'>
                            <h6 className='title'>CONTINUE READING</h6>
                        </div>      
                    </section>
                </div>

                <div className='latest-blog-main'>
                    <div className='latest-blog'>
                        <div className='latest-blog-inner2'>
                        {recentBlogs.length > 0 ? (
                                    recentBlogs.map((blog, index) => (
                                        <div key={index} className='latest-blog-inner'>
                                            <div className='latest-blog-item'>
                                                <div className='latest-blog-img' style={{ backgroundImage: `url(${blog.imageurl})` }}></div>
                                                <div className='latest-blog-content'>
                                                    <h3>{blog.title}</h3>
                                                    <p className='blog-summary-latest'>{blog.summary}</p>
                                                    <div className='blog-details-latest'>
                                                        <p><strong>{blog.author}</strong> |</p>
                                                        <p>{formatDate(blog.publish)}</p>
                                                    </div>
                                                    <button onClick={() => handleReadMore(blog.id)}>Read more</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p>No blogs have been published today.</p>
                                )}
                        </div>
                    </div>
                    <div className='shortcut-contact'>
                                <Fade left>
                        <div className='shortcut-about'>
            
                            <h5>About Our Company</h5>
                            <p>We provide Cybersecurity and AI research and consultancy services.</p>
                        <button onClick={() => navigate('/about')}>Read more</button>
                        </div>
                        </Fade>
                        <Fade right>
                        <div className='shortcut-about'>
                            <h5>Get in touch</h5>
                            <p>Guardians of your digital fortress, securing every click and byte.</p>
                        <button onClick={() => navigate('/contact')}>Read more</button>
                        </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default LatestBlog
import React from 'react'
import Navbar1 from '../Components/Navbar1'
import Footer1 from '../Components/Footer1'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage, faPhone, faMailBulk, faWindowRestore, faPerson ,faHashtag} from '@fortawesome/free-solid-svg-icons'
import './Scam.css'
import { useNavigate } from 'react-router-dom'
function Scam() {
    const navigate=useNavigate();
    return (

    <div>
        <Navbar1/>
        <div className='scam-con-main'>
            <div className='title-con'>
                <h3>Scam Aid</h3>
            </div>
        </div>
        <div className='scam-con2'>
            <h2>
            Ways scammers reach you
            </h2>
            <div className='scam-row'>
                <div className='scam-col1'>
                    {/* <img src='images/message.png' alt='text scam' className='scam-imgs'/> */}
                    <FontAwesomeIcon icon={faMessage} className='scam-imgs'/>
                    <h4>Text or SMS Scams</h4>
                    <p>Scam messages disguise themselves as trusted entities, aiming to deceive and exploit unsuspecting targets.</p>
                </div>
                <div className='scam-col1'>
                    {/* <img src='images/phone.png' alt='text scam' className='scam-imgs'/> */}
                    <FontAwesomeIcon icon={faPhone} className='scam-imgs'/>
                    <h4>Phone scams</h4>
               <p>Scam calls are more than annoying - they cost Australians $141 million in losses last year.</p>
                </div>
                <div className='scam-col1'>
                    {/* <img src='images/email.png' alt='text scam' className='scam-imgs'/> */}
                    <FontAwesomeIcon icon={faMailBulk} className='scam-imgs'/>
                    <h4>Email scams</h4>
              <p>Watch out for deceptive emails posing as genuine, hiding harmful links or attachments to exploit you.</p>
                </div>
            </div>

            <div className='scam-row'>
                <div className='scam-col1'>
                    {/* <img src='images/message.png' alt='text scam' className='scam-imgs'/> */}
                    <FontAwesomeIcon icon={faHashtag} className='scam-imgs'/>
                    <h4>Social Media Scams</h4>
                    <p>Scam messages disguise themselves as trusted entities, aiming to deceive and exploit unsuspecting targets.</p>
                </div>
                <div className='scam-col1'>
                    {/* <img src='images/phone.png' alt='text scam' className='scam-imgs'/> */}
                    <FontAwesomeIcon icon={faWindowRestore} className='scam-imgs'/>
                    <h4>Website scams</h4>
               <p>Scam calls are more than annoying - they cost Australians $141 million in losses last year.</p>
                </div>
                <div className='scam-col1'>
                    {/* <img src='images/email.png' alt='text scam' className='scam-imgs'/> */}
                    <FontAwesomeIcon icon={faPerson} className='scam-imgs'/>
                    <h4>In-Person scams</h4>
              <p>Watch out for deceptive emails posing as genuine, hiding harmful links or attachments to exploit you.</p>
                </div>
            </div>
            <div className='scam-help'>
                <h4> Scam email analysis and advisory service. </h4>
                <span>Do you need advice on:</span>
                <p onClick={()=>{navigate('/contact')}}>How to take action now?</p>
                <p onClick={()=>{navigate('/contact')}}>How to report a scam to the authorities?</p>
                <p onClick={()=>{navigate('/contact')}}>Have you been a victim of a scam email?</p>
            </div>
        </div>
        <Footer1/>
    </div>
  )
}

export default Scam
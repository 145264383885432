import React from 'react'
import './Home1.css'
import Carousel from 'react-bootstrap/Carousel';

function Home1() {
  return (
    <div className='main-home'>
<Carousel fade interval={2500} wrap={true}>
      <Carousel.Item>
        <img src='images/slider6.jpg' className='slider-img'/>
        <Carousel.Caption className='caption2'>
          <h3 >Secure Your Future, Protect Your Data.</h3>
          {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src='images/slider5.jpg' className='slider-img'/>
        <Carousel.Caption className='caption2'>
          <h3>Protecting your digital assets, securing tomorrow.</h3>
          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        {/* <ExampleCarouselImage text="Third slide" /> */}
        <img src='images/slider2.jpg' className='slider-img'/>
        <Carousel.Caption className='caption2'>
          <h3>Safeguard Your Inbox with Our Expertise.</h3>
          {/* <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p> */}
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </div>
  )
}

export default Home1
import React, { useState, useEffect } from 'react';
import './BlogDisplay.css'
import { db } from '../Firebase'
import { useNavigate, Link } from 'react-router-dom';

function BlogDisplay() {
    const [isVisible, setIsVisible] = useState(false);
    const navigate = useNavigate();
    const currentDate = new Date();
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const scrollPosition = window.scrollY + window.innerHeight;
        const elementPosition = document.querySelector('.main-blog-display').offsetTop;
        if (scrollPosition > elementPosition) {
            setIsVisible(true);
        }
    };

    const [blogs, setBlogs] = useState([]);
    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const snapshot = await db.collection('Blog').get();
                const fetchedBlogs = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setBlogs(fetchedBlogs);
            } catch (error) {
                console.error('Error fetching blogs: ', error);
            }
        };


        fetchBlogs();
    }, []);

    const formatDate = (timestamp) => {
        const date = new Date(timestamp.seconds * 1000); // Convert Firestore timestamp to JavaScript Date object
        return date.toLocaleDateString(); // Format date as a string
    };

    const handleReadMore = (blogId) => {
        navigate(`/blog-details/${blogId}`);
    };

    // Filter recent blogs based on publish date
    const recentBlogs = blogs.filter(blog => {
        const publishDate = blog.publish.toDate();
        const twentyFourHoursAgo = new Date(currentDate - 24 * 60 * 60 * 1000);
        return publishDate >= twentyFourHoursAgo && publishDate <= currentDate;
    });

    return (
        <div className={`main-blog-display ${isVisible ? 'visible' : ''}`}>
            <div className='Recent-blog'>
                <h6>Recent blogs</h6>
                
                <ul>
                    {recentBlogs.map((blog, index) => (
                        <li key={index}>
                            <h7>{blog.title}</h7>
                            {/* <button onClick={() => handleReadMore(blog.id)}>{blog.title}</button> */}
                            <p>Publish date: {formatDate(blog.publish)}</p>
                            <button onClick={() => handleReadMore(blog.id)}>Read more</button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className='blog-display-inner'>
                {blogs.map((blog, index) => (
                    <div key={index} className='content-container'>
                        {/* <img src={blog.imageurl} alt={`Image for ${blog.title}`} /> */}
                        <div style={{ backgroundImage: `url(${blog.imageurl})`}} className='blogimg'></div>
                        <h3>{blog.title}</h3>
                        <p>{blog.summary}</p>
                        <div className='blog-display-inner2'>
                            <div className='author'>
                                <p><strong>Author: </strong>{blog.author}</p>
                                <p><strong>Publish date: </strong>{formatDate(blog.publish)}</p>
                            </div>
                            <button onClick={() => handleReadMore(blog.id)}>Read more</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default BlogDisplay;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../Firebase';
import Navbar1 from './Navbar1';
import Footer1 from './Footer1';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import './BlogDetails.css';

function BlogDetails() {
    const [blog, setBlog] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchBlogDetails = async () => {
            try {
                const doc = await db.collection('Blog').doc(id).get();
                if (doc.exists) {
                    // Convert newline characters to actual line breaks
                    const formattedContent = doc.data().content.replace(/\\n/g, '\n');
                    setBlog({ id: doc.id, ...doc.data(), content: formattedContent });
                } else {
                    console.error('Blog post not found');
                }
            } catch (error) {
                console.error('Error fetching blog details: ', error);
            }
        };

        fetchBlogDetails();
    }, [id]);

    const formatDate = (timestamp) => {
        const date = new Date(timestamp.seconds * 1000);
        return date.toLocaleDateString();
    };

    if (!blog) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Navbar1 />
            <div className='blog-con-main'>
                <div className='title-con'>
                    <h3>Blog</h3>
                </div>
            </div>
            <div className='main-blog-details'>
                <div className="blog-details-inner">
                    <div className="content-container">
                        <div style={{ backgroundImage: `url(${blog.imageurl})` }} className='blogimg2'></div>
                        <h3>{blog.title}</h3>
                        <p><strong>Summary: </strong>{blog.summary}</p>
                        {/* Use formattedContent here */}
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{blog.content}</ReactMarkdown>
                        <div className="blog-details-inner2">
                            <div className="author">
                                <p><strong>Author: </strong>{blog.author}</p>
                                <p><strong>Publish date: </strong>{formatDate(blog.publish)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer1 />
        </div>
    );
}

export default BlogDetails;

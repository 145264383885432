import React from 'react'
import './Research.css';
import Navbar1 from '../Components/Navbar1';
import Footer1 from '../Components/Footer1';
import { Navbar } from 'react-bootstrap';
function Research() {
  return (
    <div>
        <Navbar1/>
        <div className='research-con-main'>
            <div className='title-con'>
                <h3>Research Services</h3>
            </div>
        </div>

        <div className='mdo-con2'>
          <p>We provide custom Cybersecurity and AI research services for Cybersecurity products</p>
        </div>
        <Footer1/>

    </div>
  )
}

export default Research
import React from 'react';
import './Home2.css';
import HomeServices from './HomeServices';
import Home3 from './Home3';
function Home2() {
  return (
    <div>
        <div className='Home2content'>
            <div className='content-wraper'>
                <div className='main'>
                    <section className='section-home2'>
                        <div className='section-inner'>
                            <h6 className='title'>DATA DEFENSE</h6>
                        </div>
                        <div className='section-inner2'>
                            <h2>Welcome to the forefront of digital protection, where our cutting-edge cybersecurity services company stands as a bastion of defense in an increasingly interconnected world.</h2>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    <HomeServices/>
    <Home3/>
        {/* <div className='services-section'>
            <div className='services-section-1'>
                <h2>We ensure 
a holistic risk–based approach for our customer organizations with solutions </h2>
            </div>
            <div className='services-section-2'>
                <div className='services-section-2-col1'>
                    <div className='service-img'></div>
                    <h3>HIPPA</h3>
                    <p>HIPAA compliance is the process that business associates and covered entities follow to protect and secure Protected Health Information (PHI) as prescribed by the Health Insurance Portability and Accountability Act.</p>
                    <button>Read more</button>
                </div>
                <div className='services-section-2-col1'>
                <div className='service-img'></div>
                    <h3>HIPPA</h3>
                    <p>HIPAA compliance is the process that business associates and covered entities follow to protect and secure Protected Health Information (PHI) as prescribed by the Health Insurance Portability and Accountability Act.</p>
                    <button>Read more</button>
                </div>
            </div>
        </div> */}
    </div>
  )
}

export default Home2
import React from 'react'
import './AboutCon.css'

function AboutCon() {
  return (
    <div>
        <div className='about-con-main'>
            <div className='title-con'>
                <h3>About Us</h3>
            </div>
        </div>
        <div className='about-con-2'>
            <h2>Welcome to the forefront of digital protection, where our cutting-edge cybersecurity services company stands as a bastion of defense in an increasingly interconnected world.</h2>
        </div>
        <div className='about-con-3'>
            <h3>With cyber threats evolving every day, our comprehensive suite of services, including meticulous security assessments and rigorous penetration testing, is designed to fortify your digital assets.</h3>
            <h5>We delve deep into the complexities of hardware and IoT security assessments, ensuring that even the most sophisticated of devices are shielded from malicious intents</h5>
            <p>Our robust Security Operation Center (SOC) and managed defence operations serve as the nerve center for relentless vigilance. Meanwhile, our managed detection and response systems employ precision and swiftness to counteract cyber incidents before they escalate. In the critical hours following a breach, our incident response and readiness teams spring into action, providing swift and strategic resolutions. Beyond immediate risks, we focus on strategic readiness, encompassing governance, risk and compliance, to create an infrastructure resilient to the threats of tomorrow. Complementing our defensive depth is our advancement in DevOps and DevSecOps services, integrating security seamlessly into the development lifecycle and ensuring that every line of code contributes to a safer cyber environment. Trust us to be your dedicated ally in navigating the complex cybersecurity landscape.</p>
        </div>
        <div className='about-con-4'>
            <h2>Holds The Right Expertise & Skillset</h2>
        </div>
    </div>
  )
}

export default AboutCon
import React from 'react'
import './Footer1.css';
// import logo2 from '../footerlogo2.png';
// import logo1 from '../footerlogo1.png'
import logo2 from '../footerlogoupdated.png'

function Footer1() {
  return (
    <div className='main-footer'>
        <div className='inner-footer'>
            <div className='footer-col1'>
                {/* <img src={logo1} className='footer-img1'/> */}
                <img src={logo2} alt='Data Defense' className='footer-img'/>
            </div>
            
            <div className='footer-col3'>
                <h6>Services</h6>
                <a href='/mdo'>Managed Defense Operations (MDO)</a>
                <a href='/sapt'>Security Assessment & Penetration Testing</a>
                <a href='/scam'>Scam Aid</a>
                <a href='/research'>Research Services</a>

            </div>
            <div className='footer-col4'>
                <h6>Email Info</h6>
                <p>dataadefense@gmail.com</p>
                <h6>Contact</h6>
                <a href='/contact'>You need any help?</a>
            </div>
            <div className='footer-col2'>
                <h6>Location Address</h6>
                <p>30B Trinidad Road Sunnynook Auckland NZ</p>
                {/* <p>Contact Address</p> */}
                </div> 
        </div>
        <div className='copyright'>
            <p>&copy; 2024 Data Defense All Rights Reserved. </p>
        </div>
    </div>
  )
}

export default Footer1
import React from 'react';
import './Home3.css';
import { useNavigate } from 'react-router-dom';
import {Fade} from 'react-reveal'
function Home3() {
    const navigate=useNavigate();
  return (
    <div>
        <div className='home3-main'>
          <Fade bottom>
            <h2>Data Defense holds the right expertise and skillset</h2>
            <p>When organizations realize that incidents are inevitable, they feel that incident readiness is critical to their business continuity. These organizations try to find a security partner who is trustworthy and holds the industry standard expertise of incident response.</p>
            <button onClick={()=>navigate('/about')}>Read more</button>
            </Fade>
        </div>
    </div>
  )
}

export default Home3